<template>
  <b-tabs
    ref="tabs"
    v-model="activeTab"
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
    @changed="handleTabChangedEvent"
    @activate-tab="handleTabChangedEvent"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          :icon="tabsList[0].icon"
          size="18"
          class="mr-50"
        />
        <span
          v-t="tabsList[0].title"
          class="font-weight-bold"
        />
      </template>

      <settings-tab-loader
        v-if="isLoading"
      />
      <settings-general
        v-else-if="companySettings"
        :settings="companySettings"
        @submit="payload => submitSettings('company', payload.settings, payload.callback)"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- domain tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          :icon="tabsList[1].icon"
          size="18"
          class="mr-50"
        />
        <span
          v-t="tabsList[1].title"
          class="font-weight-bold"
        />
      </template>

      <settings-tab-loader
        v-if="isLoading"
      />
      <domain-list
        v-else
      />
    </b-tab>
    <!--/ domain tab -->

    <!-- accounts tab -->
    <b-tab v-if="0">

      <!-- title -->
      <template #title>
        <feather-icon
          :icon="tabsList[1].icon"
          size="18"
          class="mr-50"
        />
        <span
          v-t="tabsList[1].title"
          class="font-weight-bold"
        />
      </template>

      <settings-tab-loader
        v-if="isLoading"
      />
      <settings-accounts
        v-else-if="options.notification"
        :data="options.notification"
      />
    </b-tab>
    <!--/ accounts tab -->

    <!-- checkout tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          :icon="tabsList[2].icon"
          size="18"
          class="mr-50"
        />
        <span
          v-t="tabsList[2].title"
          class="font-weight-bold"
        />
      </template>

      <settings-tab-loader
        v-if="isLoading"
      />
<!--      <settings-checkout-->
<!--        v-else-if="checkoutSettings"-->
<!--        :settings="checkoutSettings"-->
<!--        @submit="payload => submitSettings('checkout', payload.settings, payload.callback)"-->
<!--      />-->
      <settings-checkout-v2
        v-else-if="checkoutSettings"
        :settings="checkoutSettings"
        @submit="payload => submitSettings('checkout', payload.settings, payload.callback)"
      />
    </b-tab>
    <!--/ checkout tab -->

    <!-- vat tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          :icon="tabsList[3].icon"
          size="18"
          class="mr-50"
        />
        <span
          v-t="tabsList[3].title"
          class="font-weight-bold"
        />
      </template>

      <settings-tab-loader
        v-if="isLoading"
      />
      <settings-vat
        v-else-if="vatSettings"
        :settings="vatSettings"
        @submit="payload => submitSettings('vat', payload.settings, payload.callback)"
      />
    </b-tab>
    <!--/ vat tab -->

    <!-- social tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          :icon="tabsList[4].icon"
          size="18"
          class="mr-50"
        />
        <span
          v-t="tabsList[4].title"
          class="font-weight-bold"
        />
      </template>

      <settings-tab-loader
        v-if="isLoading"
      />
      <settings-social
        v-else-if="socialSettings"
        :settings="socialSettings"
        @submit="payload => submitSettings('social_links', payload.settings, payload.callback)"
      />
    </b-tab>
    <!--/ social tab -->

    <!-- layout tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          :icon="tabsList[5].icon"
          size="18"
          class="mr-50"
        />
        <span
          v-t="tabsList[5].title"
          class="font-weight-bold"
        />
      </template>
      <settings-tab-loader
        v-if="isLoading"
      />
      <layout-settings
        v-else-if="layoutSettings"
        :settings="{}"
      />
<!--      :settings.sync="layoutSettings"-->
    </b-tab>
    <!--/ layout tab -->

    <!-- seo tab -->
    <b-tab>
      <!-- title -->
      <template #title>
        <feather-icon
          :icon="tabsList[6].icon"
          size="18"
          class="mr-50"
        />
        <span
          v-t="tabsList[6].title"
          class="font-weight-bold"
        />
      </template>

      <settings-tab-loader
        v-if="isLoading"
      />
      <settings-seo
        v-else-if="seoSettings"
        :settings="seoSettings"
        @submit="payload => submitSettings('seo_tags', payload.settings, payload.callback)"
      />
    </b-tab>
    <!--/ seo tab -->

    <!-- pages tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          :icon="tabsList[7].icon"
          size="18"
          class="mr-50"
        />
        <span
          v-t="tabsList[7].title"
          class="font-weight-bold"
        />
      </template>
      <page-list />
    </b-tab>
    <!--/ pages tab -->

    <!-- menus tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          :icon="tabsList[8].icon"
          size="18"
          class="mr-50"
        />
        <span
          v-t="tabsList[8].title"
          class="font-weight-bold"
        />
      </template>
      <settings-tab-loader
        v-if="isLoading"
      />
      <page-list />
    </b-tab>
    <!--/ menus tab -->
  </b-tabs>
</template>

<script>
import { BTab, BTabs } from 'bootstrap-vue'
import SettingsAccounts from '@/views/models/settings/general-settings/SettingsAccounts.vue'
import store from '@/store'
import settingStoreModule from '@/views/models/settings/general-settings/settingStoreModule'
import {
  computed, nextTick, onBeforeMount, onUnmounted, ref, watch,
} from 'vue'
import { toast, useRouter } from '@core/utils/utils'
import SettingsTabLoader from '@/views/loaders/SettingsTabLoader.vue'
import PageList from '@/views/models/pages/list/PageList.vue'
import SettingsSocial from '@/views/models/settings/general-settings/SettingsSocial.vue'
import SettingsVat from '@/views/models/settings/general-settings/SettingsVat.vue'
import { setStoreData } from '@/auth/utils'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import SettingsCheckout from '@/views/models/settings/general-settings/SettingsCheckout.vue'
import SettingsCheckoutV2 from '@/views/models/settings/general-settings/SettingsCheckoutV2.vue'
import SettingsSeo from '@/views/models/settings/general-settings/SettingsSeo.vue'
import SettingsGeneral from './SettingsGeneral.vue'
import LayoutSettings from '../layout-settings/Index.vue'
import DomainList from '@/views/models/domains/list/DomainList.vue'

export default {
  components: {
    DomainList,
    SettingsSeo,
    SettingsCheckout,
    SettingsCheckoutV2,
    SettingsVat,
    SettingsSocial,
    PageList,
    SettingsTabLoader,
    LayoutSettings,
    SettingsAccounts,
    BTabs,
    BTab,
    SettingsGeneral,
  },
  data() {
    return {
      options: {
        general: {
          store_name: 'متجر أثاث',
          industry: 'الأثاث المنزلي',
          email: 'granger007@hogward.com',
          logo: null,
          mobile: '+201234567890',
          domain: 'fashion',
        },
        info: {
          bio: '',
          dob: null,
          country: 'USA',
          website: '',
          phone: 6562542568,
        },
        social: {
          socialLinks: {
            twitter: 'https://www.twitter.com',
            facebook: '',
            google: '',
            linkedIn: 'https://www.linkedin.com',
            instagram: '',
            quora: '',
          },
          connections: {
            twitter: {
              profileImg: require('@/assets/images/avatars/11-small.png'),
              id: 'johndoe',
            },
            google: {
              profileImg: require('@/assets/images/avatars/3-small.png'),
              id: 'luraweber',
            },
            facebook: {},
            github: {},
          },
        },
        notification: {
          commentOnArticle: true,
          AnswerOnForm: true,
          followMe: false,
          newAnnouncements: true,
          productUpdates: true,
          blogDigest: false,
        },
      },
      company_settings: {},
      // layout_settings: null,
    }
  },
  computed: {
    hasTouchedAttributes() {
      return Object.entries(this.touchedAttributes).length
    },
  },
  beforeCreate() {
    // this.$http.get('/account-setting/data').then(res => { this.options = res.data })

    // this.$http.get('/dashboard/layout_settings').then(res => {
    //   const response = res.data
    //   if (response.success) {
    //     this.layout_settings = response.data.settings
    //   }
    // })
  },
  methods: {
    handleTabChangedEvent() {
      nextTick(() => {
        const selectedTabIndex = this.$refs.tabs.currentTab
        document.getElementById('appBreadcrumb')
          .getElementsByClassName('active')[0]
        // .textContent = (tabsList[selectedTabIndex].title)
          .textContent = this.$refs.tabs.$refs.nav.children[selectedTabIndex].textContent

        const targetRoute = this.tabsList[selectedTabIndex].route
        // router.replace(targetRoute)
        if (JSON.stringify(targetRoute.params) !== JSON.stringify(this.$route.params)) {
          this.$router.push(targetRoute)
        }
      })
    },
  },
  setup(props, ctx) {
    const STORE_MODULE_NAME = 'settings'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, settingStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const { t } = useI18nUtils()
    const companySettings = ref({})
    const domainSettings = ref({})
    const vatSettings = ref({})
    const checkoutSettings = ref({})
    const socialSettings = ref({})
    const layoutSettings = ref({})
    const seoSettings = ref({})
    const activeTab = ref(0)
    const isLoading = ref(true)
    const tabsList = [
      {
        title: 'modules.settings.pages.general',
        icon: 'SettingsIcon',
        route: { name: 'generalSettings', params: { category: 'store', subcategory: 'general' } },
      },
      {
        title: 'modules.settings.pages.domain',
        icon: 'GlobeIcon',
        route: { name: 'generalSettings', params: { category: 'store', subcategory: 'domain' } },
      },
      {
        title: 'modules.settings.pages.checkout',
        icon: 'ShoppingCartIcon',
        route: { name: 'generalSettings', params: { category: 'store', subcategory: 'checkout' } },
      },
      {
        title: 'modules.settings.pages.vat',
        icon: 'PercentIcon',
        route: { name: 'generalSettings', params: { category: 'store', subcategory: 'vat' } },
      },
      // {
      //   title: 'modules.settings.pages.accounts',
      //   icon: 'UsersIcon',
      //   route: { name: 'generalSettings', params: { category: 'store', subcategory: 'account' } },
      // },
      {
        title: 'modules.settings.pages.social',
        icon: 'Share2Icon',
        route: { name: 'generalSettings', params: { category: 'store', subcategory: 'social' } },
      },
      {
        title: 'modules.settings.pages.layout',
        icon: 'LayoutIcon',
        route: { name: 'generalSettings', params: { category: 'layout', subcategory: 'main' } },
      },
      {
        title: 'modules.settings.pages.seo',
        icon: 'SearchIcon',
        route: { name: 'generalSettings', params: { category: 'store', subcategory: 'seo' } },
      },
      {
        title: 'modules.settings.pages.pages',
        icon: 'FileTextIcon',
        route: { name: 'generalSettings', params: { category: 'store', subcategory: 'pages' } },
      },
      {
        title: 'modules.settings.pages.menus',
        icon: 'LinkIcon',
        route: { name: 'menus', params: { category: 'store', subcategory: 'menus' } },
      },
    ]

    const { route } = useRouter()
    const routeParams = computed(() => route.value.params)

    const resolveRouteParams = params => {
      tabsList.forEach((t, i) => {
        if (t.route.params.category === params.category && t.route.params.subcategory === params.subcategory) {
          nextTick(() => {
            activeTab.value = i
          })
        }
      })
    }
    resolveRouteParams(routeParams.value)

    watch(routeParams, newValue => {
      resolveRouteParams(routeParams.value)
    })

    // watch([routeParams], params => resolveRouteParams(params))

    const fetchSettings = () => {
      isLoading.value = true
      store.dispatch(`${STORE_MODULE_NAME}/fetchAll`)
        .then(response => {
          if (response.data.success) {
            companySettings.value = response.data.data.company
            domainSettings.value = response.data.data.domain
            vatSettings.value = response.data.data.vat
            checkoutSettings.value = response.data.data.checkout
            socialSettings.value = response.data.data.social
            layoutSettings.value = response.data.data.layout_settings
            seoSettings.value = response.data.data.seo_tags

            isLoading.value = false
          }
        })
    }

    onBeforeMount(() => {
      fetchSettings()
    })

    const submitSettings = (namespace, settings, callback) => {
      store.dispatch(`${STORE_MODULE_NAME}/updateMany`, { namespace, settings })
        .then(response => {
          if (response.success) {
            fetchSettings()
          }
          callback(true)

          toast(
            'success',
            t('message.operation_success'),
            null,
            'CheckCircleIcon',
          )
        }).catch(error => {
          if (error?.response?.status === 422) {
            const errors = []
            Object.values(error.response.data.errors).forEach(attributesErrors => {
              Object.values(attributesErrors).forEach(attributeErrors => {
                if (typeof (attributeErrors) === 'object') {
                  Object.values(attributeErrors).forEach(attributeError => {
                    errors.push(attributeError)
                  })
                } else {
                  errors.push(attributeErrors)
                }
              })
            })
            toast(
              'warning',
              t('message.generic_validation_error'),
              null,
              'AlertTriangleIcon',
              null,
              errors,
            )
          } else {
            toast(
              'danger',
              t('message.operation_failed'),
              null,
              'AlertTriangleIcon',
              null,
            )
            callback(false, error)
          }
        }).finally(() => {
          callback(null)
        })
    }

    return {
      submitSettings,
      companySettings,
      domainSettings,
      vatSettings,
      checkoutSettings,
      socialSettings,
      layoutSettings,
      seoSettings,
      activeTab,
      tabsList,
      isLoading,
    }
  },
}
</script>
