<template>
  <b-row>
    <b-col
      v-for="input in schema"
      :key="input.id"
      :md="input.cols || 6"
    >
      <i-input
        v-show="!shouldHide(input)"
        :id="input.id"
        :ref="input.id"
        v-model="value[input.id]"
        :errors="validationErrors[input.id]"
        :type="input.type || 'text'"
        :title="input.label"
        :placeholder="input.placeholder || ' '"
        :description="input.description || ''"
        :required="Object.hasOwn(input, 'required') ? input.required : true"
        :translatable="input.localized || false"
        :append="input.append || null"
        :prepend="input.prepend || null"
        :disabled="shouldDisabled(input)"
      />
      <Transition name="list-enter-up">
        <b-alert
          v-for="(alert, index) in alerts(input)"
          :key="`alert-${index}`"
          :variant="alert.type"
          class="mb-1"
          show
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="InfoIcon"
            />
            <span class="ml-25">{{ alert.text }}</span>
          </div>
        </b-alert>
      </Transition>
    </b-col>
  </b-row>
</template>

<script>
import {
  BAlert,
  BCol,
  BRow,
} from 'bootstrap-vue'
import { getLocale } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BAlert,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    schema: {
      type: Array,
      default: Array,
    },
    value: {
      type: Object,
      required: true,
    },
    validationErrors: {
      type: Object,
      default: Object,
    },
  },
  created() {
    //
  },
  methods: {
    getLocale,
    getSettingInputType: type => {
      if (type === 'file') {
        return 'file'
      }
      return type
    },
    getSettingInputBinding: input => {
      const bindings = {}
      if (Object.hasOwn(input, 'class')) {
        bindings['input-bindings'] = {
          class: input.class,
        }
      }
      if (Object.hasOwn(input, 'style')) {
        bindings['input-bindings'] = {
          class: input.style,
        }
      }
      if (input.type === 'file') {
        return {
          ...bindings,
          size: 'lg',
          autoUpload: false,
        }
      }
      if (input.type === 'select') {
        return {
          ...bindings,
          options: input.options,
          reduce: o => o.value,
        }
      }
      return bindings
    },
  },
  setup(props, { emit }) {
    const filterValidationRules = rules => rules.filter(rule => ['required'].indexOf(rule) >= 0)

    /**
     * @param {Object} condition
     * @param {boolean} fallback
     * @param {'and', 'or'} operator
     */
    const evaluateCondition = (condition, fallback, operator = 'and') => {
      if (!Object.keys(condition).length) {
        return fallback
      }
      return operator === 'and' ? Object.keys(condition).every(key => {
        const expectedValue = condition[key]
        return expectedValue === props.value[key]
      }) : Object.keys(condition).some(key => {
        const expectedValue = condition[key]
        return expectedValue === props.value[key]
      })
    }

    /**
     * @param {Array} condition
     * @param {boolean} fallback
     */
    const evaluateConditions = (condition, fallback) => {
      if (!Object.values(condition).length) {
        return fallback
      }

      if (!(condition instanceof Array)) {
        return evaluateCondition(condition, fallback, 'and')
      }

      const conditions = condition.map(childCondition => {
        if (childCondition instanceof Array) {
          return evaluateConditions(childCondition, fallback)
        }
        return evaluateCondition(childCondition, fallback, 'or')
      })
      return conditions.every(childCondition => childCondition)
    }

    const shouldHide = input => {
      if (!Object.hasOwn(input, 'hideIf') || !Object.values(input.hideIf).length) {
        return false
      }
      return evaluateConditions(input.hideIf, false)
    }

    const shouldDisabled = input => {
      if (input.disabled) {
        return true
      }
      if (!Object.hasOwn(input, 'disableIf') || !Object.values(input.disableIf).length) {
        return false
      }
      return evaluateConditions(input.disableIf, false)
    }

    const alerts = input => {
      if (!Object.hasOwn(input, 'alerts') || !(input.alerts instanceof Array) || !input.alerts.length) {
        return []
      }
      return input.alerts.filter(alert => !Object.hasOwn(alert, 'hideIf') || !evaluateConditions(alert.hideIf, false))
    }

    // const inputsFlags = computed(() => {
    //   console.log('Refreshing inputs flags', props.value)
    //   return props.schema.reduce((acc, input) => {
    //     acc[input.id] = {
    //       value: input.value,
    //       hidden: shouldHide(input),
    //       disabled: shouldDisabled(input),
    //     }
    //     return acc
    //   }, {})
    // })

    return {
      filterValidationRules,
      shouldHide,
      shouldDisabled,
      alerts,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
.modal-body label {
  font-size: unset;
}
</style>
