<template>
  <div>
    <dynamic-form-card
        v-for="(formSchema, index) in formsSchema"
        :key="`formSchema-${index}`"
        :title="formSchema.title"
        :schema="formSchema.elements"
        :value="settings"
        @submit="payload => $emit('submit', payload)"
    />
  </div>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BFormTextarea, BAlert, BCardText, BLink, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import {shop} from "@core/utils/utils";
import store from "@/store";
import settingStoreModule from "@/views/models/settings/general-settings/settingStoreModule";
import {onUnmounted, ref} from "vue";
import DynamicFormCard from "@/views/components/forms/DynamicFormCard.vue";

export default {
  components: {
    DynamicFormCard,
    BModal,
    BLink,
    BCardText,
    BAlert,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      localSettings: JSON.parse(JSON.stringify(this.settings)),
      isLoading: false,
      remoteErrors: {},

      buyNowBtn: require('@/assets/images/illustration/example/buy-now-btn.svg'),
    }
  },
  computed: {
    touchedAttributes() {
      const attrs = {}

      Object.keys(this.settings).forEach(settingKey => {
        let currentValue = this.localSettings[settingKey]
        if (settingKey === 'phone') {
          currentValue = `+${this.localSettings.dialCode}${this.localSettings.phone}`
        }
        if (typeof (this.settings[settingKey]) === 'object') {
          if (JSON.stringify(currentValue) !== JSON.stringify(this.settings[settingKey])) {
            attrs[settingKey] = currentValue
          }
        } else if (currentValue !== this.settings[settingKey]) {
          attrs[settingKey] = currentValue
        }
      })
      return attrs
    },
    hasTouchedAttributes() {
      return Object.entries(this.touchedAttributes).length
    },
    getStoreCountryName() {
      const storeCountry = this.shop()?.country
      const currentLocale = this.$i18n.locale
      if (storeCountry) {
        return storeCountry[`name_${currentLocale}`]
      }
      if (this.$i18n.locale === 'ar') {
        return 'دولتك'
      }
      return 'your country'
    },
  },
  watch: {
    settings() {
      this.resetForm()
    },
  },
  methods: {
    shop,
    resetForm() {
      this.localSettings = JSON.parse(JSON.stringify(this.settings))
    },
    onSubmitHandler() {
      this.isLoading = true
      this.$emit('submit', {
        settings: this.touchedAttributes,
        callback: (result, error) => {
          this.isLoading = false

          if (result === false) {
            if (error?.response?.data?.errors) {
              this.remoteErrors = error?.response?.data?.errors
            }
          }
        },
      })
    },
    validationError(attr) {
      if (attr === 'confirmation_page_message') {
        return this.validationError('confirmation_page_message.ar') || this.validationError('confirmation_page_message.en')
      }
      return this.remoteErrors[`settings.${attr}`]?.[0] || null
    },
    validationErrorsArray(attr) {
      const error = this.validationError(attr)
      return error ? [error] : []
    },


  },
  setup(props, ctx) {
    const STORE_MODULE_NAME = 'settings'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, settingStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const formsSchema = ref([])
    const isLoading = ref(false)

    const fetchOptions = () => {
      isLoading.value = true
      store.dispatch(`${STORE_MODULE_NAME}/fetchOptions`, 'checkout')
          .then(response => {
            formsSchema.value = response.forms
          })
          .finally(() => {
            isLoading.value = false
          })
    }
    fetchOptions()

    return {
      formsSchema,
      isLoading,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
