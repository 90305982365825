<template>
  <b-card :title="title">
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Body -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <DynamicForm
          :schema="schema"
          :value="localValue"
          @update:value="v => localValue = v"
        />

        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            :disabled="!hasTouchedAttributes || isLoading"
            type="submit"
          >
            {{ $t(`Save Changes`) }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            :disabled="isLoading"
            @click.prevent="resetForm"
          >
            {{ $t('Reset') }}
          </b-button>
        </div>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { getLocale } from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import { computed, ref } from 'vue'
import DynamicForm from '@/views/components/forms/DynamicForm.vue'
import { BButton, BCard, BForm } from 'bootstrap-vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationObserver,
    BForm,
    BButton,
    BCard,
    DynamicForm,
  },
  directives: {
    Ripple,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    schema: {
      type: Array,
      default: Array,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  created() {
    //
  },
  methods: {
    getLocale,
  },
  setup(props, { emit }) {
    const isLoading = ref(false)
    const localValue = ref({})
    const remoteErrors = ref({})
    function isEmpty(val) {return (val === "") || (val === null)}
    const touchedAttributes = computed(() => {
      const attrs = {}

      Object.keys(props.value).forEach(settingKey => {
        const currentValue = localValue.value[settingKey]
        const originalValue = props.value[settingKey]
        // if (settingKey === 'phone') {
        //   currentValue = `+${localValue.value.dialCode}${localValue.value.phone}`
        // }
        if (typeof (originalValue) === 'object') {
          if (JSON.stringify(currentValue) !== JSON.stringify(originalValue)) {
            attrs[settingKey] = currentValue
          }
        } else if (!(isEmpty(currentValue) && isEmpty(originalValue)) && (currentValue !== originalValue)) {
          attrs[settingKey] = currentValue
        }
      })
      return attrs
    })

    const hasTouchedAttributes = computed(() => Object.entries(touchedAttributes.value).length)

    const resetFormInputs = () => {
      localValue.value = JSON.parse(JSON.stringify(props.value))
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetFormInputs)

    const onSubmit = () => {
      console.log('Submit')
      isLoading.value = true
      emit('submit', {
        settings: touchedAttributes.value,
        callback: (result, error) => {
          isLoading.value = false

          if (result === false) {
            if (error?.response?.data?.errors) {
              remoteErrors.value = error?.response?.data?.errors
            }
          }
        },
      })
    }


    const validationError = (attr) => {
      if (attr === 'confirmation_page_message') {
        return validationError('confirmation_page_message.ar') || validationError('confirmation_page_message.en')
      }
      return remoteErrors[`settings.${attr}`]?.[0] || null
    }
    const validationErrorsArray = (attr) => {
      const error = validationError(attr)
      return error ? [error] : []
    }

    resetForm()

    return {
      isLoading,
      localValue,
      touchedAttributes,
      hasTouchedAttributes,

      // Form Validation
      onSubmit,
      resetForm,
      getValidationState,
      refFormObserver,
    }
  },
}</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-knowledge-base.scss';
.modal-body label {
  font-size: unset;
}
</style>
